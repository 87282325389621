<template>
  <!-- eslint-disable vue/valid-v-slot -->
  <!-- eslint-disable vue/no-v-text-v-html-on-component -->
  <div>
    <div v-if="getInfoErrorMessage || !canManageRelease">
      <no-data
        with-button
        :first-text="
          !canManageRelease
            ? 'You don\'t have permissions to view sessions'
            : getInfoErrorMessage
        "
      ></no-data>
    </div>
    <template v-else>
      <v-row v-if="initialLoad">
        <v-col cols="12">
          <v-skeleton-loader type="table"></v-skeleton-loader>
        </v-col>
      </v-row>

      <template v-else>
        <!-- No Data -->
        <template v-if="!sessionData.length && canManageRelease">
          <get-started></get-started>
          <!--         <ReleaseEmptyState-->
          <!--           from-feedback-page-->
          <!--           iframe-link="https://demo.arcade.software/jwywgoLQTgJPG4KWZG2b?embed'"-->
          <!--           sub-title="from inside your app"-->
          <!--           title="Session & Feedback"-->
          <!--         />-->
        </template>
        <!-- sessions List -->
        <template v-else-if="sessionData.length">
          <v-row
            cols="12"
            justify="space-between"
            sm="12"
            style="margin-top: 24px"
            xs="12"
          >
            <v-col>
              <h1 class="darkGrey--text">
                Sessions <sup class="smallFont">BETA</sup>
              </h1>
            </v-col>
          </v-row>

          <v-row align="center">
            <v-col cols="12" lg="10" md="10" rounded xl="10">
              <GroupStatusCard dot-type="icon" :stats="stats1" />
            </v-col>

            <v-col class="py-0" lg="2" md="2" rounded sm="12" xl="2" xs="12">
              <GroupStatusCard :stats="activeSessions" dotType="circle" />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <DatatableWrapper
                :clickableRow="true"
                :loading="loading"
                :pagination="pagination"
                @onChange="handleOnChange"
              >
                <v-data-table
                  v-model="selected"
                  :headers="headers"
                  :items="sessionData"
                  :loading="loading"
                  disable-pagination
                  disable-sort
                  hide-default-footer
                  item-class="table-row"
                  item-key="id"
                  @click:row="handleClick"
                >
                  <template #item.device.model="{ item }">
                    <flex-row gap="8px">
                      <v-icon
                        class="mr-1"
                        size="24"
                        v-text="
                          item.device.name === 'Android'
                            ? 'mdi-android'
                            : 'mdi-apple'
                        "
                      />
                      <stack gap="0px">
                        <span> {{ item.device.model }}</span>
                        <span class="primary--text">
                          {{ item.device.os.version }}</span
                        >
                      </stack>
                    </flex-row>
                  </template>

                  <template #item.app.name="{ item }">
                    <stack gap="0px">
                      <div
                        :title="item?.application?.name ?? 'Anonymous'"
                        class="font14 truncate"
                        style="max-width: 180px"
                      >
                        {{ item?.application?.name ?? "Anonymous" }}
                      </div>
                      <span class="primary--text"
                        >v{{ item.application.version }} ({{
                          item.application.version_code
                        }})</span
                      >
                    </stack>
                  </template>

                  <template #item.stats.users="{ item }">
                    <flex-row gap="8px">
                      <v-icon size="24">mdi mdi-account-circle</v-icon>

                      <!--                    <div-->
                      <!--                      :title="item?.user?.name ?? 'Anonymous'"-->
                      <!--                      class="font14"-->
                      <!--                      style="-->
                      <!--                        white-space: nowrap;-->
                      <!--                        overflow: hidden;-->
                      <!--                        text-overflow: ellipsis;-->
                      <!--                        max-width: 280px;-->
                      <!--                      "-->
                      <!--                    >-->
                      <!--                      {{ item?.user?.name ?? "Anonymous" }}-->
                      <!--                    </div>-->
                      <MaxText
                        :max="50"
                        :text="item?.user?.name ?? 'Anonymous'"
                      ></MaxText>

                      <div
                        v-if="item.stats.users > 1"
                        class="font14 users-count"
                      >
                        +{{ item.stats.users - 1 }}
                      </div>
                    </flex-row>
                  </template>

                  <template #item.stats.errors="{ item }">
                    <div
                      style="
                        display: flex;
                        justify-content: center;
                        align-items: center;
                      "
                    >
                      <v-icon
                        :class="item.stats.errors > 0 ? 'error--text' : ''"
                        class="mr-1"
                        size="24"
                        >mdi mdi-alert-circle
                      </v-icon>
                      <span :class="item.stats.errors > 0 ? 'error--text' : ''">
                        {{ item?.stats?.errors ?? 0 | formatNumber }}
                      </span>
                    </div>
                  </template>

                  <template #item.stats.feedback="{ item }">
                    <v-btn
                      class="primary--text"
                      icon
                      @click.stop="
                        $router.push({
                          name: 'session-details-feedback',
                          params: {
                            session_id: item.id,
                          },
                        })
                      "
                    >
                      {{ item?.stats?.feedback ?? 0 | formatNumber }}
                    </v-btn>
                  </template>

                  <template #item.stats="{ item }">
                    <v-tooltip bottom>
                      <template #activator="{ on, attrs }">
                        <v-btn
                          class="primary--text"
                          icon
                          v-bind="attrs"
                          v-on="on"
                          @click.stop="
                            $router.push({
                              name: 'session-details-activities',
                              params: {
                                session_id: item.id,
                              },
                            })
                          "
                        >
                          {{
                            [item?.stats?.screens, item?.stats.events]
                              | sum
                              | formatNumber
                          }}
                        </v-btn>
                      </template>
                      <ul>
                        <li>
                          Screens:
                          {{ item?.stats?.screens ?? 0 | formatNumber }}
                        </li>
                        <li>
                          Events: {{ item?.stats.events ?? 0 | formatNumber }}
                        </li>
                      </ul>
                    </v-tooltip>
                  </template>

                  <template #item.end_at="{ item }">
                    <v-icon
                      :class="item.status == 1 ? 'success--text' : null"
                      class="mr-1"
                      size="24"
                      >mdi mdi-timer
                    </v-icon>
                    <span :class="item.status == 1 ? 'success--text' : null">{{
                      getDuration(item.start_at, item.end_at)
                    }}</span>
                  </template>

                  <template #item.start_at="{ item }">
                    <v-icon class="mr-1" size="24"
                      >mdi mdi-calendar-range
                    </v-icon>
                    <vue-hoverable-date
                      v-if="item.start_at"
                      :date="item.start_at | unix"
                    ></vue-hoverable-date>
                  </template>
                </v-data-table>
              </DatatableWrapper>
            </v-col>
          </v-row>
        </template>
      </template>
    </template>
  </div>
</template>

<script>
import { GET_ALL_SESSIONS } from "@/store/apps/sessions.module";
import dayjs from "dayjs";
import GroupStatusCard from "@/view/components/Sessions/GroupStatusCard";
import DatatableWrapper from "@/view/components/App/DatatableWrapper";
// import ReleaseEmptyState from "@/view/components/Releases/ReleaseEmptyState.vue";
import { getDuration } from "@/core/services/helper.service";
import { mapGetters } from "vuex";
import GetStarted from "@/view/components/Sessions/GetStarted";

export default {
  components: { GetStarted, GroupStatusCard, DatatableWrapper },
  data() {
    return {
      dayjs,
      sessionStats: [],
      endpoint: GET_ALL_SESSIONS,
      totalSessions: 0,
      loading: false,
      initialLoad: true,
      sessionData: [],
      headers: [
        {
          text: "Device",
          align: "start",
          value: "device.model",
          width: 0,
          cellClass: "no-wrap",
        },
        {
          text: "Application",
          align: "start",
          value: "app.name",
          cellClass: "no-wrap",
          width: 150,
        },
        {
          text: "User",
          align: "start",
          value: "stats.users",
          width: 300,
        },
        {
          text: "Errors",
          value: "stats.errors",
          align: "center",
          width: 140,
        },
        {
          text: "Feedback",
          value: "stats.feedback",
          align: "center",
          width: 140,
        },
        {
          text: "Activities",
          align: "center",
          value: "stats",
          width: 140,
        },
        {
          text: "Duration",
          align: "start",
          value: "end_at",
          width: 140,
        },
        {
          text: "Date",
          align: "start",
          value: "start_at",
          width: 0,
          cellClass: "no-wrap",
        },
      ],
      selected: [],
      pagination: {
        page: this.toNumber(this.$route.query.page),
        itemsPerPage: 10,
        totalItems: 0,
      },
      sessionStatus: {
        Ended: 0,
        Active: 1,
        Expired: 2,
      },
      mockData: {
        anonymous_id: "A95635DD-DD44-4480-B552-9E45F4E58AA9",
        app: { id: "Ovy2lQyoDZ", name: "Sample SDK", image: "" },
        device: {
          id: "nOaYVGr2mr",
          model: "arm64",
          os: { name: "iOS", version: "16.4", sdk_version: "16.4" },
          type: "Handset",
        },
        end_at: "2023-05-27T15:13:07.655+00:00",
        id: "qR1o3Ny2Z0",
        location: {
          city: "Sharjah",
          country: "United Arab Emirates",
          country_code: "ARE",
        },
        sdk_app: {
          id: "pPKoKR2MGD",
          name: "TestAppSDKExample",
          package: "testapp.io.sample-app",
          version: "1.0",
        },
        start_at: "2023-05-27T15:13:03.655+00:00",
        stats: { users: 2, events: 2, performance: 2, user_attributes: 2 },
        status: 2,
        user: {
          id: "9AOYaj525p",
          name: "Victor",
          email: "victor@mail.com",
          image: null,
          identity: "random",
        },
      },
    };
  },
  created() {
    if (!this.getInfoErrorMessage && this.canManageRelease) {
      this.getAllSessionData().then(() => {
        this.initialLoad = false;
      });
    }
  },
  methods: {
    getDuration,
    reloadSessions() {
      this.getAllSessionData();
    },
    handleClick(item) {
      this.$router.push({
        name: "sessions-detail",
        params: { app_id: item?.app?.id, session_id: item?.id },
      });
    },
    async getAllSessionData(lastId, page = this.pagination.page) {
      this.loading = true;

      return this.$store
        .dispatch(GET_ALL_SESSIONS, {
          app_id: this.$route.params.app_id || "",
          last_id: lastId || "",
          user_id: this.$route.query.user_id || "",
          application_id: this.$route.query.application_id || "",
          page,
        })
        .then((response) => {
          this.sessionData = response?.data ?? [];

          if (this.initialLoad) {
            this.sessionStats = response?.meta?.stats;
            this.totalSessions = response?.meta?.total;
            this.pagination.totalItems = this.totalSessions;
          }
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getTimeDiff(start, end) {
      if (!end) {
        return "In Progress";
      } else {
        let first = dayjs(start);
        let second = dayjs(end);
        return second.diff(first, "h");
      }
    },
    handleOnChange(value) {
      this.pagination.page = value;
    },
    getDate(date) {
      return dayjs(date).unix();
    },
    getActivities(session) {
      let count = 0;
      if (session?.stats?.events) count += session?.stats?.events;
      if (session?.stats?.screens) count += session?.stats?.screens;
      return count;
    },
  },
  computed: {
    ...mapGetters({
      getInfoErrorMessage: "getInfoErrorMessage",
    }),
    sessions() {
      return {
        title: "Sessions",
        count: this.$options?.filters?.formatNumber(this.totalSessions),
      };
    },
    stats() {
      return [
        this.sessions,
        this.sessionsErrors,
        this.activities,
        this.feedback,
        this.attachments,
      ];
    },
    stats1() {
      return [
        {
          title: "Sessions",
          icon: "mdi-timeline-text-outline",
          color: "primary",
          count: this.$options?.filters?.formatNumber(this.totalSessions || 0),
        },
        {
          title: "Errors",
          icon: "mdi-alert-circle",
          color: "error",
          count: this.$options?.filters?.formatNumber(
            this.sessionStats?.errors ?? 0
          ),
        },
        {
          title: "Activities",
          icon: "mdi-cursor-default-click",
          color: "primary",
          count: this.$options?.filters?.formatNumber(
            (this.sessionStats?.events ?? 0) + (this.sessionStats?.screens ?? 0)
          ),
          tooltip: `
              <ul>
                <li>Screens: ${this.sessionStats?.screens ?? 0}</li>
                <li>Events: ${this.sessionStats?.events ?? 0}</li>
              </ul>
            `,
        },
        {
          title: "Feedback",
          icon: "mdi-message-alert",
          color: "primary",
          count: this.$options?.filters?.formatNumber(
            this.sessionStats?.feedback ?? 0
          ),
        },
        {
          title: "Devices",
          icon: "mdi-cellphone",
          color: "primary",
          count: this.$options?.filters?.formatNumber(
            this.sessionStats?.devices ?? 0
          ),
        },
      ];
    },
    activities() {
      const count =
        0 +
        (this.sessionStats?.events ?? 0) +
        (this.sessionStats?.screens ?? 0);
      const tooltip = `
        <ul>
          <li>Screens: ${this.sessionStats?.screens ?? 0}</li>
          <li>Events: ${this.sessionStats?.events ?? 0}</li>
        </ul>
      `;
      return {
        title: "Activities",
        count: this.$options?.filters?.formatNumber(count),
        tooltip,
      };
    },
    feedback() {
      return {
        title: "Feedback",
        count: this.$options?.filters?.formatNumber(
          this.sessionStats?.feedback ?? 0
        ),
      };
    },
    attachments() {
      return {
        title: "Attachments",
        count: this.$options?.filters?.formatNumber(
          this.sessionStats?.attachments || 0
        ),
      };
    },
    sessionsErrors() {
      let count = 0;
      if (this.sessionStats?.errors) count += this.sessionStats?.errors;
      return {
        title: "Errors",
        count: this.$options?.filters?.formatNumber(count),
      };
    },
    activeSessions() {
      return [
        {
          title: "Active Sessions",
          count: this.$options?.filters?.formatNumber(
            this.sessionStats.active || 0
          ),
        },
      ];
    },
    lastId() {
      return this.sessionData[this.sessionData.length - 1]?.id;
    },
  },
  watch: {
    "pagination.page"(newPage) {
      this.$router.push({
        name: "app-sessions",
        params: {
          app_id: this.$route.params.app_id,
        },
        query: {
          ...this.$route.query,
          page: newPage,
        },
      });

      this.pagination.page = newPage;
      this.getAllSessionData(this.lastId, newPage);
    },
  },
};
</script>

<style>
.text-gray {
  color: #e0e0e0;
}

.users-count {
  background: #4062d7;
  border-radius: 100%;
  width: 24px;
  height: 24px;
  text-align: center;
  color: #ffffff;
}
</style>

<style scoped>
.column {
  display: flex;
  flex-direction: column;
}

.rotate-icon {
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}

.rotate-icon {
  transform: rotate(0deg);
  transition: transform 0.3s ease;
}

.animate-icon {
  animation: rotateAnimation 1s linear;
}

@keyframes rotateAnimation {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
</style>
