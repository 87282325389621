var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.getInfoErrorMessage || !_vm.canManageRelease ? _c('div', [_c('no-data', {
    attrs: {
      "with-button": "",
      "first-text": !_vm.canManageRelease ? 'You don\'t have permissions to view sessions' : _vm.getInfoErrorMessage
    }
  })], 1) : [_vm.initialLoad ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-skeleton-loader', {
    attrs: {
      "type": "table"
    }
  })], 1)], 1) : [!_vm.sessionData.length && _vm.canManageRelease ? [_c('get-started')] : _vm.sessionData.length ? [_c('v-row', {
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      "cols": "12",
      "justify": "space-between",
      "sm": "12",
      "xs": "12"
    }
  }, [_c('v-col', [_c('h1', {
    staticClass: "darkGrey--text"
  }, [_vm._v(" Sessions "), _c('sup', {
    staticClass: "smallFont"
  }, [_vm._v("BETA")])])])], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "10",
      "md": "10",
      "rounded": "",
      "xl": "10"
    }
  }, [_c('GroupStatusCard', {
    attrs: {
      "dot-type": "icon",
      "stats": _vm.stats1
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "lg": "2",
      "md": "2",
      "rounded": "",
      "sm": "12",
      "xl": "2",
      "xs": "12"
    }
  }, [_c('GroupStatusCard', {
    attrs: {
      "stats": _vm.activeSessions,
      "dotType": "circle"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('DatatableWrapper', {
    attrs: {
      "clickableRow": true,
      "loading": _vm.loading,
      "pagination": _vm.pagination
    },
    on: {
      "onChange": _vm.handleOnChange
    }
  }, [_c('v-data-table', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.sessionData,
      "loading": _vm.loading,
      "disable-pagination": "",
      "disable-sort": "",
      "hide-default-footer": "",
      "item-class": "table-row",
      "item-key": "id"
    },
    on: {
      "click:row": _vm.handleClick
    },
    scopedSlots: _vm._u([{
      key: "item.device.model",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('flex-row', {
          attrs: {
            "gap": "8px"
          }
        }, [_c('v-icon', {
          staticClass: "mr-1",
          attrs: {
            "size": "24"
          },
          domProps: {
            "textContent": _vm._s(item.device.name === 'Android' ? 'mdi-android' : 'mdi-apple')
          }
        }), _c('stack', {
          attrs: {
            "gap": "0px"
          }
        }, [_c('span', [_vm._v(" " + _vm._s(item.device.model))]), _c('span', {
          staticClass: "primary--text"
        }, [_vm._v(" " + _vm._s(item.device.os.version))])])], 1)];
      }
    }, {
      key: "item.app.name",
      fn: function fn(_ref2) {
        var _item$application$nam, _item$application, _item$application$nam2, _item$application2;
        var item = _ref2.item;
        return [_c('stack', {
          attrs: {
            "gap": "0px"
          }
        }, [_c('div', {
          staticClass: "font14 truncate",
          staticStyle: {
            "max-width": "180px"
          },
          attrs: {
            "title": (_item$application$nam = item === null || item === void 0 ? void 0 : (_item$application = item.application) === null || _item$application === void 0 ? void 0 : _item$application.name) !== null && _item$application$nam !== void 0 ? _item$application$nam : 'Anonymous'
          }
        }, [_vm._v(" " + _vm._s((_item$application$nam2 = item === null || item === void 0 ? void 0 : (_item$application2 = item.application) === null || _item$application2 === void 0 ? void 0 : _item$application2.name) !== null && _item$application$nam2 !== void 0 ? _item$application$nam2 : "Anonymous") + " ")]), _c('span', {
          staticClass: "primary--text"
        }, [_vm._v("v" + _vm._s(item.application.version) + " (" + _vm._s(item.application.version_code) + ")")])])];
      }
    }, {
      key: "item.stats.users",
      fn: function fn(_ref3) {
        var _item$user$name, _item$user;
        var item = _ref3.item;
        return [_c('flex-row', {
          attrs: {
            "gap": "8px"
          }
        }, [_c('v-icon', {
          attrs: {
            "size": "24"
          }
        }, [_vm._v("mdi mdi-account-circle")]), _c('MaxText', {
          attrs: {
            "max": 50,
            "text": (_item$user$name = item === null || item === void 0 ? void 0 : (_item$user = item.user) === null || _item$user === void 0 ? void 0 : _item$user.name) !== null && _item$user$name !== void 0 ? _item$user$name : 'Anonymous'
          }
        }), item.stats.users > 1 ? _c('div', {
          staticClass: "font14 users-count"
        }, [_vm._v(" +" + _vm._s(item.stats.users - 1) + " ")]) : _vm._e()], 1)];
      }
    }, {
      key: "item.stats.errors",
      fn: function fn(_ref4) {
        var _item$stats$errors, _item$stats;
        var item = _ref4.item;
        return [_c('div', {
          staticStyle: {
            "display": "flex",
            "justify-content": "center",
            "align-items": "center"
          }
        }, [_c('v-icon', {
          staticClass: "mr-1",
          class: item.stats.errors > 0 ? 'error--text' : '',
          attrs: {
            "size": "24"
          }
        }, [_vm._v("mdi mdi-alert-circle ")]), _c('span', {
          class: item.stats.errors > 0 ? 'error--text' : ''
        }, [_vm._v(" " + _vm._s(_vm._f("formatNumber")((_item$stats$errors = item === null || item === void 0 ? void 0 : (_item$stats = item.stats) === null || _item$stats === void 0 ? void 0 : _item$stats.errors) !== null && _item$stats$errors !== void 0 ? _item$stats$errors : 0)) + " ")])], 1)];
      }
    }, {
      key: "item.stats.feedback",
      fn: function fn(_ref5) {
        var _item$stats$feedback, _item$stats2;
        var item = _ref5.item;
        return [_c('v-btn', {
          staticClass: "primary--text",
          attrs: {
            "icon": ""
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.$router.push({
                name: 'session-details-feedback',
                params: {
                  session_id: item.id
                }
              });
            }
          }
        }, [_vm._v(" " + _vm._s(_vm._f("formatNumber")((_item$stats$feedback = item === null || item === void 0 ? void 0 : (_item$stats2 = item.stats) === null || _item$stats2 === void 0 ? void 0 : _item$stats2.feedback) !== null && _item$stats$feedback !== void 0 ? _item$stats$feedback : 0)) + " ")])];
      }
    }, {
      key: "item.stats",
      fn: function fn(_ref6) {
        var _item$stats$screens, _item$stats4, _item$stats$events;
        var item = _ref6.item;
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref7) {
              var _item$stats3;
              var on = _ref7.on,
                attrs = _ref7.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                staticClass: "primary--text",
                attrs: {
                  "icon": ""
                },
                on: {
                  "click": function click($event) {
                    $event.stopPropagation();
                    return _vm.$router.push({
                      name: 'session-details-activities',
                      params: {
                        session_id: item.id
                      }
                    });
                  }
                }
              }, 'v-btn', attrs, false), on), [_vm._v(" " + _vm._s(_vm._f("formatNumber")(_vm._f("sum")([item === null || item === void 0 ? void 0 : (_item$stats3 = item.stats) === null || _item$stats3 === void 0 ? void 0 : _item$stats3.screens, item === null || item === void 0 ? void 0 : item.stats.events]))) + " ")])];
            }
          }], null, true)
        }, [_c('ul', [_c('li', [_vm._v(" Screens: " + _vm._s(_vm._f("formatNumber")((_item$stats$screens = item === null || item === void 0 ? void 0 : (_item$stats4 = item.stats) === null || _item$stats4 === void 0 ? void 0 : _item$stats4.screens) !== null && _item$stats$screens !== void 0 ? _item$stats$screens : 0)) + " ")]), _c('li', [_vm._v(" Events: " + _vm._s(_vm._f("formatNumber")((_item$stats$events = item === null || item === void 0 ? void 0 : item.stats.events) !== null && _item$stats$events !== void 0 ? _item$stats$events : 0)) + " ")])])])];
      }
    }, {
      key: "item.end_at",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_c('v-icon', {
          staticClass: "mr-1",
          class: item.status == 1 ? 'success--text' : null,
          attrs: {
            "size": "24"
          }
        }, [_vm._v("mdi mdi-timer ")]), _c('span', {
          class: item.status == 1 ? 'success--text' : null
        }, [_vm._v(_vm._s(_vm.getDuration(item.start_at, item.end_at)))])];
      }
    }, {
      key: "item.start_at",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [_c('v-icon', {
          staticClass: "mr-1",
          attrs: {
            "size": "24"
          }
        }, [_vm._v("mdi mdi-calendar-range ")]), item.start_at ? _c('vue-hoverable-date', {
          attrs: {
            "date": _vm._f("unix")(item.start_at)
          }
        }) : _vm._e()];
      }
    }]),
    model: {
      value: _vm.selected,
      callback: function callback($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  })], 1)], 1)], 1)] : _vm._e()]]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }